/* eslint-disable */
<template>

<div class="login-panel">
	        <div class="loginwidth">
	            <img v-if="showimg" id="profile-img" class="profile-img-card" :src="require(`../../assets/serviceimg/${serviceimg}.png`)" />
	            <p id="profile-name" class="profile-name-card"></p>
              <div v-if="showLoginForm">
                <div class="form-signin" >
                  <form>
                  <span id="reauth-email" class="reauth-email"></span>
                  <div class="entry">
                    <labelc text="E-Mail"/>
                    <input type="email" autocomplete="off" placeholder="Email" v-model="email"/>
                    <labelc type="input-error" v-if="errors['email']" :text="errors['email'].text"/>
                    <buttonc type="input-error" icon="fa-exclamation-circle" v-bind:class="[errors['email'].type]" v-if="errors['email']"  v-tooltip="errors['email'].tooltip"/>
                  </div>
                  <div class="entry">
                    <labelc text="Passwort"/>
                    <input type="password" placeholder="Passwort" v-model="password" autocomplete="current-password"/>
                    <labelc type="input-error" v-if="errors['password']" :text="errors['password'].text"/>
                    <buttonc type="input-error" icon="fa-exclamation-circle" v-bind:class="[errors['password'].type]" v-if="errors['password']"  v-tooltip="errors['password'].tooltip"/>
                  </div>
                  </form>
                    <!--<div class ="mt-2">
                        <vue-recaptcha  align="center" ref="recaptcha" sitekey="6LeweXcUAAAAAEK3680JMMYmGxwPF5chsgypi_w7" @verify="onCaptchaVerified" @expired="onCaptchaExpired"></vue-recaptcha>
                    </div>-->
                  <div class="footer no-divider">
                    <div class="align-left" style="padding-right: 20px;">
                      <a href="#" class="forgot-password" @click="OpenForgotPasswordPopup()">
                        Passwort vergessen?
                      </a>
                     
                    </div>
                    <div class="align-right" style="flex: initial">
                      <buttonc v-if="!loading" type="rectangle green footer" label="Log in"  @click="recaptchaLogin" style="width: 90px;"/>
                      <buttonc v-if="loading" type="rectangle green footer" icon="fa-spinner" style="width: 90px;"/>
                    </div>
                     
                  </div>
                  <div class="googleterms">
                    <p>This site is protected by reCAPTCHA and the Google
                        <a href="https://policies.google.com/privacy">Privacy Policy</a> and
                        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                    </p>
                  </div>
                  
                </div><!-- /form -->
                
              </div>
              <div>
                <form ref="redirectForm" id="redirectForm" style="display:none" action="" method="POST">
                    <input name="token" type="text" v-model="token"/>
                </form>
            	</div>
	        </div><!-- /card-container -->
          <v-dialog/>
          
          <modal name="2FModal" class="custom-dialog" height="auto" :width="400" :pivot-y="0.3" :adaptive="true">
            <div class="dialog-content">
              <div class="dialog-c-title">2F-Authentifizierung</div>
              <div class="dialog-c-text">
                <p>Bitte geben Sie einen gültigen 2F Code ein: </p>
                <div class="form-signin">
                    <input type="text" class="form-control" v-model.number="twofactor" placeholder="Zwei Faktor Code" required/>
                  </div>
              </div>
            </div>
            <div class="custom-dialog-buttons">
              <button class= 'custom-dialog-button' type="button" :style="buttonStyle" @click="hideCustomModal('2FModal')">
                Abbrechen
              </button>
              <button class= 'custom-dialog-button' type="button" :style="buttonStyle" @click="submitLogin()">
                Ok
              </button>
            </div>
          </modal>
</div><!-- /container -->

</template>

<script>
import axios from 'axios';
import dropdowncontainer from '../../components/uicomponents/dropdowncontainer.vue'
import labelc from '../../components/uicomponents/label.vue'
import buttonc from '../../components/uicomponents/button.vue'
export default {
    components: {
        dropdowncontainer,
        labelc,
        buttonc
    },
  name: 'loginpanel',
  data () {
    return {
      email: '',
      password: '',
      twofactor: null,
      redirect: '',
      gcaptcha:'',
      token: '',
      showimg: false,
      showLoginForm: false,
      serviceimg: "logo",
        errors: {},
        loading: false,
    }
  },
  created: function () {
    let view = this;
    this.email = this.$route.query.email;
    this.redirect = this.$route.query.redirect;
    this.setServiceImg();
    this.checkShowLoginForm();
    window.setTimeout(function () { view.loginWithToken(); }, 500)
  },
  mounted() {
    /*console.log("AAA")
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded')
    recaptchaScript.async = true;
    recaptchaScript.defer = true;
    document.head.appendChild(recaptchaScript); */
  },
  // methods that implement data logic.
  methods: {
    setServiceImg: function(){
      if(!this.isBlank(this.redirect)){
        if(this.redirect.includes("mz")){
				  this.serviceimg = "mz";
        } else if(this.redirect.includes("mp")) {
          this.serviceimg = "mp";
        } else if(this.redirect.includes("mdcore")) {
          this.serviceimg = "md";
        } else {
          this.serviceimg = "logo";
        }
      } else {
         this.serviceimg = "logo";
      }

      this.showimg=true;

    },
    checkShowLoginForm: function(){
      if (this.supportsHTML5Storage() && localStorage.getItem('md_token') != null) {
        this.showLoginForm = false;
      } else {
        this.showLoginForm = true;
      }
    },
    isBlank: function (str) {
		    return (!str || /^\s*$/.test(str))
    },
    getQueryStringValue: function (key) {
			  return decodeURIComponent(window.location.search.replace(new RegExp('^(?:.*[&\\?]' + encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&') + '(?:\\=([^&]*))?)?.*$', 'i'), '$1'))
    },
    supportsHTML5Storage: function () {
		    try {
		        return 'localStorage' in window && window['localStorage'] !== null
		    } catch (e) {
		        return false
		    }
    },
    onCaptchaVerified(captcha) {
      this.gcaptcha = captcha;
    },
    onCaptchaExpired(){
      this.$refs.recaptcha.reset();
      this.gcaptcha='';
    },
      OpenForgotPasswordPopup(){
          let view = this;
          this.$helpers.OpenPopup({
              header: 'Passwort zurücksetzen',
              width: '600px',
              components: [{
                  type: 'datafill',
                  state: {
                      entries: [
                            {key: 'info', type: 'output', value: 'Bitte geben Sie die Email Adresse Ihres Accounts an:', label:''},
                            {key: 'email', type: 'text', value: view.email, label:'', placeholder: 'E-Mail'}
                          ],
                      errorCheck: [
                          (entries) => {
                              if(!view.$helpers.ValidEmailCheck(entries['email'])) return {key: 'email', type: 'error', text: 'Falsche E-mail'}
                              else return null;
                          },
                      ],
                      selectCallback: (data, datafill) => {
                          datafill.loading = true;
                          view.requestPasswordReset(data.email).then((response) => {
                              view.toggleModal('Erfolg', '<p>Eine Email zum Zurücksetzen des Passworts wurde versandt!</p>');
                              datafill.Toggle();
                          }).catch((error) => {
                              datafill.loading = false;
                              if (error.response != undefined && error.response.data.message != undefined) {
                                  datafill.errors = {};
                                  datafill.errors['email'] = {
                                      key: 'email',
                                      type: 'error',
                                      text: 'Änderung konnte nicht durchgeführt werden',
                                      tooltip: 'Bitte versuchen Sie es erneut oder kontaktieren Sie einen Administrator'
                                  };
                              }
                          })
                      },
                  },
              }],
              centered: true,

          });
      },
    recaptchaLogin() {

      let view = this;
      this.loading = true;
      this.$recaptchaLoaded().then(() => {

        this.$recaptcha('login').then((token) => {
          view.gcaptcha = token;
          view.submitLogin();
        })
      })
    },
    submitLogin: function () {
      this.hideCustomModal('2FModal');
      if (!this.isBlank(this.password) && !this.isBlank(this.email) && !this.isBlank(this.gcaptcha)) {
        this.modalMessage = ''
        this.modalPayload = ''
        let payload = {
          email: this.email,
          password: this.password,
          gcaptcha: this.gcaptcha
        }
        if(this.twofactor != null){
          payload.twofactor = this.twofactor;
        }
        let url = '/api/login'
        if (!this.isBlank(this.redirect)) {
          url = url + '?redirect=' + this.redirect + '&source=frontend'
        }
        let view = this;
        axios({
          method: 'post',
          url: url,
          data: payload
        }).then(response => {
          //view.$refs.recaptcha.reset();
          view.token = response.data.token
          if (view.supportsHTML5Storage()) {
            localStorage.setItem('md_token', view.token)
          } else {
            // TODO: Handle case of browser not supporting local storage
          }


          if (response.data.redirect != null) {
            view.showLoginForm = false;
            window.setTimeout(function () { view.redirectTo(response.data.redirect) }, 500)
          } else {
            view.goToProfile();
          }
        }).catch(function (error) {

            view.loading = false;
          let reset_captcha = true;
          view.twofactor = null;
          view.showLoginForm = true;
          if(error.response != undefined && error.response.data.data.cause != undefined){
            let cause = error.response.data.data.cause;
            if(cause == 'INVALID_CAPTCHA'){
              view.toggleModal('Fehler', 'Ungültiges Captcha, bitte versuchen Sie es erneut oder wenden Sie sich an einen Administrator.', '');
            } else if(cause == 'NOT_ACTIVATED'){
              view.toggleModal('Fehler', 'Ihr Account wurde noch nicht aktiviert.', 'Bitte überprüfen Sie Ihr Email-Konto oder kontaktieren Sie einen Administrator.');
            } else if(cause == 'MISSING_2F'){
              reset_captcha = false;
              view.showCustomModal('2FModal');
            } else if(cause == 'INVALID_2F'){
              view.toggleModal('Fehler', 'Ungültiger 2F Code, bitte versuchen Sie es erneut.', '');
            }  else {
                view.errors = {};
                view.errors.password = {type: 'error', text: 'bitte überprüfen Sie das Passwort'};
              //view.toggleModal('Fehler', 'Login fehlgeschlagen, bitte überprüfen Sie das Passwort.', '');
            }

          } else {
            view.errors = {};
            view.errors.password = {type: 'error', text: 'bitte überprüfen Sie das Passwort'};//view.toggleModal('Fehler', 'Login fehlgeschlagen, bitte überprüfen Sie das Passwort.', '');
          }
          /*if(reset_captcha){
            view.$refs.recaptcha.reset();
            view.gcaptcha='';
          }*/

        });
      } else {

        this.toggleModal('Fehler', 'Bitte geben Sie sowohl ihre Email Adresse als auch ihr Passwort ein und füllen Sie das Captcha aus!');
      }
    },
    loginWithToken: function () {
      if (this.supportsHTML5Storage() && localStorage.getItem('md_token') != null) {
        this.modalMessage = ''
        this.modalPayload = ''

        let url = '/api/login?renew=true'
        if (!this.isBlank(this.redirect)) {
          url = url + '&redirect=' + this.redirect + '&source=frontend'
        }
        let view = this;
        let token = localStorage.getItem('md_token')
			  axios({
          method: 'get',
          url: url,
          headers: {'Authorization': 'Bearer ' + token}
        }).then(response => {
			    view.token = response.data.token
          localStorage.setItem('md_token', view.token)

          if (response.data.redirect != null) {
            view.showLoginForm = false;
            window.setTimeout(function () { view.redirectTo(response.data.redirect) }, 500)
          } else {
            view.goToProfile();
          }
        }).catch(function (error) {
          view.showLoginForm = true;
          let reset_captcha = true;
          if(error.response != undefined && error.response.data.data.cause != undefined){
            let cause = error.response.data.data.cause;
            if(cause == 'TOKEN_EXPIRED'){
              view.toggleModal('Fehler', 'Ihr Login ist abgelaufen.', 'Bitte melden Sie sich erneut an.');
            } else if(cause == 'NOT_ACTIVATED'){
              view.toggleModal('Fehler', 'Ihr Account wurde noch nicht aktiviert.', 'Bitte überprüfen Sie Ihr Email-Konto oder kontaktieren Sie einen Administrator.');
            } else {
               view.toggleModal('Fehler', 'Login fehlgeschlagen, bitte melden Sie sich erneut an.', '');
            }

          } else {
            view.toggleModal('Fehler', 'Login fehlgeschlagen, bitte melden Sie sich erneut an.', '');
          }
          /*if(reset_captcha){
            view.$refs.recaptcha.reset();
            view.gcaptcha='';
          }*/
        })
      }
    },
    requestPasswordReset: function (email) {
      this.hideCustomModal('requestPasswordModal');
        this.modalMessage = ''
        this.modalPayload = ''
        var payload = {
          command: 'requestPasswordReset',
          email: email
        }
        let view = this;
        return axios({
          method: 'put',
          url: '/api/user',
          data: payload
        })
    },
    redirectTo: function (url) {
      if(this.redirect.includes("mdpl")){
        window.location = url+"#/?jwt="+this.token;
      } else {
        document.getElementById('redirectForm').setAttribute("action", url);
        this.$refs.redirectForm.submit();
      }


    },
    goToProfile: function(){
      let claims = this.extractJWTInfo();
      if(claims.id != undefined){
        console.log("profile");
        this.$router.replace('/profile/'+claims.id);
      }

		},
    extractJWTInfo: function(){
      if (this.token) return JSON.parse(atob(this.token.split('.')[1]));
      return {};

    },
    showCustomModal: function(modal){
			this.$modal.show(modal);
		},
		hideCustomModal: function(modal){
			this.$modal.hide(modal);
		},
    toggleModal(title, text, text2) {
        this.$helpers.OpenInfoPopup(title, [text, text2]);
		}
  },
  computed: {
		buttonStyle () {
			return {
				flex: `1 1 ${100 / 2}%`
			}
		}

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.login-panel {
    width: 600px !important;
}

.googleterms {
  padding-top:40px;
  text-align: center;
}
  .googleterms > *{
    color: var(--contrast-6);
    font-size: 12px;
  }

  .entry .label{
    width: 100px;
  }
</style>
